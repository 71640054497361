import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import { ReportsProductDetailsRequest } from '@/types/request/reports-request.types';
import { DateTime } from 'luxon';
import {
  DATEPICKER_FORMAT,
  FILTER_MONTH_YEAR_FORMAT,
  getPrevMonthsDateRange,
  MONTH_FORMAT
} from '@/helpers/date.helper';
import StackedBarChart from '@/components/ui/charts/stacked-bar-chart/stacked-bar-chart.vue';
import { ChartData } from 'chart.js';
import { COLORS } from '@/constants/color.constant';
import { ProductDetailsType } from '@/types/api/reports/product-details.type';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import { BrandType } from '@/types/api/brands/brand.type';
import { numberFormatter } from '@/helpers/string.helper';
import AutocompleteInput from '@/components/ui/inputs/autocomplete-input/autocompleteInput.vue';

const title = 'organicRevenuePpcReport.title';

@Component({
  name: 'OrganicRevenuePpcReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    StackedBarChart: StackedBarChart as any,
    DatepickerSingle,
    AutocompleteInput,
  },
})
export default class OrganicRevenuePpcReport extends Mixins(FilterMixin) {
  title = title;
  pageFilter: any = {
    brandId: this.$route.query.brandId || null
  }
  filter: any = this.initFilter();
  useBrandFilter = true;

  @Action('getProductDetails', { namespace: 'reports' })
  getProductDetails!: (params?: ReportsProductDetailsRequest) => Promise<ProductDetailsType>;

  @Getter('getProductDetails', { namespace: 'reports' })
  productDetails!: ProductDetailsType;

  @Mutation('SET_PRODUCT_DETAILS', { namespace: 'reports' })
  setProductDetails!: (params: ProductDetailsType | null) => any;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.productId) {
      this.getProductDetailsRequest();
    }
  }

  beforeDestroy() {
    this.setProductDetails(null);
  }

  getProductDetailsRequest() {
    return this.getProductDetails(this.preparedFilter);
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(FILTER_MONTH_YEAR_FORMAT),
    }
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
    };
  }

  get statistics() {
    return this.productDetails?.statistics || [];
  }

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.statistics).map(item => item),
      datasets: [
        {
          label: 'PPC ad spend, $',
          type: 'bar',
          data: Object.values(this.statistics).map((item: any) => -item.spend) as any,
          borderColor: COLORS[0],
          backgroundColor: COLORS[0],
        },
        {
          label: 'PPC revenue, $',
          type: 'bar',
          data: Object.values(this.statistics).map((item: any) => item.ppcUnits) as any,
          borderColor: COLORS[1],
          backgroundColor: COLORS[1],
        },
        {
          label: 'Organic revenue, $',
          type: 'bar',
          data: Object.values(this.statistics).map((item: any) => item.organicUnits) as any,
          borderColor: COLORS[2],
          backgroundColor: COLORS[2],
        },
      ]
    };
  }

  get chartOptions() {
    return {
      tooltips: {
        enabled: false
      },
      hover: {
        animationDuration: 0
      },
      animation: {
        duration: 1,
        onComplete: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgb(0,0,0)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.controller.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach((bar, index) => {
                const value = numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `$ ${value}` : '',
                  bar._model.x,
                  bar._model.y
                );
              });
            }
          })
        }
      }
    }
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  get productOptions() {
    return [
      { text: '---', value: null },
      ...this.products.map(item => ({
        text: `${item.asin || ''} - ${item.displayName || item.name || ''}`,
        value: item.id
      }))
    ];
  }

  get monthFormat() {
    return MONTH_FORMAT;
  }

  getRanges() {
    const ranges = {};

    for (let i = 0; i < 12; i++) {
      const month = DateTime.local().minus({ months: i });
      const year = month.get('year');
      const label = year !== DateTime.local().get('year') ? `${month.monthLong} ${year}` : month.monthLong;

      ranges[label] = getPrevMonthsDateRange(i, true);
    }

    return ranges;
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }
}
